/** @format */

import {extendTheme, withDefaultColorScheme} from '@chakra-ui/react';
import {fonts} from './fonts';
import {fontSizes} from './typography';
import {colors} from './colors';
import {textStyles} from './textStyles';
import {spacing} from './spacing';
import {borderRadius} from './borderRadius';
import {zIndices} from './zIndices';
import {Button} from './button';
import {Badge} from './badge';
import {Accordion} from './accordion';
import {Link} from './link';

export const theme: Record<string, any> = extendTheme(
	// withDefaultColorScheme({
	// 	colorScheme: 'maroon',
	// 	components: ['Button', 'Badge'],
	// }),
	{
		styles: {
			global(props: any) {
				const {colorScheme: c} = props;

				return {
					// a: {
					//     position: "relative",
					//     cursor: "pointer",
					//     textDecoration: "underline",
					//     // for link-underline animation
					//     transition: "all .2s ease-in-out",
					//     _hover: {
					//         textDecoration: "underline",
					//         color: `${c}.[500]`,
					//         // link-underline animation
					//         "::before": {
					//             transform: "scaleX(1)",
					//         },
					//     },
					//     _disabled: {
					//         color: `${c}.[400]`,
					//     },
					//     // https://freefrontend.com/css-link-styles/
					//     "::before": {
					//         content: '""',
					//         position: "absolute",
					//         top: "100%",
					//         width: "100%",
					//         height: "2px",
					//         backgroundColor: `${c}.[500]`,
					//         transform: "scaleX(0)",
					//         transition: "transform .2s ease-in-out",
					//     },
					// },
					'.form-error': {
						fontFamily: fonts.copernicusGalaxyBook,
					},

					'.WYSIWYG': {
						// fontFamily: "Copernicus",
						// todo:design
						a: {
							lineBreak: 'anywhere',
							position: 'relative',
							cursor: 'pointer',
							textDecoration: 'underline',
							// for link-underline animation
							transition: 'all .2s ease-in-out',
							_hover: {
								textDecoration: 'none',
								color: colors.aqua[500],
								// link-underline animation
								'::before': {
									transform: 'scaleX(1)',
								},
							},
							_disabled: {
								color: colors.aqua[400],
							},
							// https://freefrontend.com/css-link-styles/
							'::before': {
								content: '""',
								position: 'absolute',
								top: '100%',
								width: '100%',
								height: '2px',
								backgroundColor: colors.aqua[500],
								transformOrigin: 'left',
								transform: 'scaleX(0)',
								transition: 'transform .2s ease-in-out',
							},
						},
					},
					// specific for room carousel
					'.WYSIWYG--default': {
						h1: {
							...textStyles.heading5Xl,
							marginBottom: '2rem',
						},
						h2: {
							...textStyles.heading4Xl,
							marginBottom: '2rem',
						},
						h3: {
							...textStyles.heading3Xl,
							marginBottom: '2rem',
						},
						h4: {
							...textStyles.heading2Xl,
							marginBottom: '2rem',
						},
						h5: {
							...textStyles.headingxl,
							marginBottom: '2rem',
						},
						h6: {
							...textStyles.headinglg,
							marginBottom: '2rem',
						},
						p: {
							...textStyles.textsm,
							marginBottom: '2rem',
						},
						strong: {
							fontWeight: 600,
						},
						'ul, ol': {
							// listStylePosition: "inside",
							listStylePosition: 'outside',
							ml: '1rem',
							marginBottom: '2rem',
							fontFamily: fonts.copernicusGalaxyBook,
						},
						'em, blockquote': {
							...textStyles.textlg,
							fontSize: '1.5rem',
							fontStyle: 'italic',
							marginBottom: '2rem',
							display: 'block',
							paddingLeft: '2rem',
							borderLeft: `4px solid ${colors.maroon[500]}`,
						},
						hr: {
							// borderColor: colors.ness[100],
							margin: '2rem 0',
						},
						small: {
							// ...textStyles.paragraph2LeftItalicDark,
							marginBottom: '2rem',
						},
					},
					'.WYSIWYG--default.thegregory': {
						h1: {
							...textStyles.heading5XlGillSans,
							marginBottom: '2rem',
						},
						h2: {
							...textStyles.heading4XlGillSans,
							marginBottom: '2rem',
						},
						h3: {
							...textStyles.heading3XlGillSans,
							marginBottom: '2rem',
							textTransform: 'uppercase'
						},
						h4: {
							...textStyles.heading2XlGillSans,
							marginBottom: '2rem',
						},
						h5: {
							...textStyles.headingxlGillSans,
							marginBottom: '2rem',
						},
						h6: {
							...textStyles.headinglgGillSans,
							marginBottom: '2rem',
						},
						p: {
							...textStyles.textsmGillSans,
							marginBottom: '2rem',
						},
						strong: {
							fontWeight: 600,
						},
						'ul, ol': {
							// listStylePosition: "inside",
							listStylePosition: 'outside',
							ml: '1rem',
							marginBottom: '2rem',
							fontFamily: fonts.GillSansMedium,
						},
						'em, blockquote': {
							...textStyles.textlg,
							fontSize: '1.5rem',
							fontStyle: 'italic',
							marginBottom: '2rem',
							display: 'block',
							paddingLeft: '2rem',
							borderLeft: `4px solid ${colors.maroon[500]}`,
						},
					},
					'.WYSIWYG--default.carousel': {
						p: {
							...textStyles.textsm,
							marginBottom: '2rem',
						},
					},
					'.image-cover': {
						objectFit: 'cover',
					},
					'.image-contain': {
						objectFit: 'contain',
					},
					'.image-scale-down': {
						objectFit: 'scale-down',
					},
					'.image-fill': {
						objectFit: 'fill',
					},

					// "html, body": {
					//     ".grecaptcha-badge": {
					//         zIndex: "999",
					//     },
					// },
				};
			},
		},
		config: {
			initialColorMode: 'light',
			useSystemColorMode: false,
		},
		fonts,
		fontSizes,
		colors,
		textStyles,
		spacing,
		borderRadius,
		zIndices,
		components: {
			Accordion,
			Button,
			Badge,
			Link,
			// Checkbox,
		},
	},
);

export default theme;
